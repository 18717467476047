<!-- Top Progress Loader -->
<ngx-loading-bar class="loader-bg" [includeSpinner]="true" [height]="'3px'"></ngx-loading-bar>

<!-- Main Loader -->
<app-loader *ngIf="themeOptionService.preloader"></app-loader>

<!-- Layout Partial -->
<app-header [logo]="setLogo().header_logo" [data]="themeOption"></app-header>
<router-outlet></router-outlet>
<app-footer [footer]="setLogo().footer" [data]="themeOption"></app-footer>

<!-- Widgets -->
<!-- <app-recent-purchase-popup></app-recent-purchase-popup> -->
<app-sticky-cart *ngIf="themeOption?.general?.cart_style == 'cart_mini'"></app-sticky-cart>
<!-- <app-sticky-compare></app-sticky-compare> -->
<div class="theme-option">
    <app-back-to-top *ngIf="themeOption?.general?.back_to_top_enable"></app-back-to-top>
</div>
<app-newsletter-modal></app-newsletter-modal>
<!-- <app-cookie></app-cookie> -->
<app-exit-modal *ngIf="exit"></app-exit-modal>
