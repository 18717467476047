import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { environment } from "../../../environments/environment";
import { CartModel } from "../interface/cart.interface";
import { CookieService } from "ngx-cookie-service";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class CartService {
  cartItem: any = null;
  cartQuantity: number = 0;
  cartQuantityEvent = new BehaviorSubject<number>(0);
  cartItemEvent = new BehaviorSubject<any>(null);
  
  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private httpService:HttpService
  ) {}

  getCartItems(): Observable<CartModel> {
    return this.http.get<CartModel>(`${environment.URL}/cart.json`);
  }

  updateCartItems(cartItem:any) {
    this.cartItem = cartItem;
    console.log(this.cartItem);
    this.cartItemEvent.next(cartItem);
  }

  updateCartQuantity(cartQuantiy:number){
    this.cartQuantity = cartQuantiy;
    this.cartQuantityEvent.next(cartQuantiy);
  }


  getCartItemsForUser() {
    const currentUser = null;
    let url:string
    if(currentUser !== null){
       url= 'api/v2/oms/cart?userId=' + currentUser.userId;
    }else{
      url = 'api/v2/oms/cart?userId=' + null
    }
    return this.httpService.get<any>(`${environment.apiServer}`+url).pipe(
      tap((response) => {
        if(response.statusCode === 200){
          this.updateCartItems(response.body.cart);
          this.updateCartQuantity(response.body.cart.products.length);
        }
      })
    );
  }

  getOrderByOrderId(orderId){
    let url:string
       url= 'api/v2/oms/order' + `?action=getOrderById&orderId=${orderId}`;
    return this.httpService.get<any>(`${environment.apiServer}`+url);


  }

  getOrderByOrderPaymentId(paymentId){
    let url:string
       url= 'api/v2/oms/order' + `?action=getOrderByPaymentId&paymentId=${paymentId}`;
    return this.httpService.get<any>(`${environment.apiServer}`+url);


  }

  addProductsToCart(products: any) {
    let url = 'api/v2/oms/cart';
    const currentUser = null;
    const cartPayload: any = {
      cartId:this.cartItem?.cartId ? this.cartItem.cartId : null,
      userId: null,
      cartTotal: 0,
      services: [],
      products: [products],
    };
    if(currentUser !== null){
      cartPayload.userId = currentUser.userId;
    }
    console.log(this.cookieService.get('_STR_C'))
    cartPayload['cookie']=this.cookieService.get('_STR_C')
    return this.httpService.post<any>(`${environment.apiServer}`+url,cartPayload).pipe(
        tap((response:any) => {
          if(response.statusCode === 200){
            this.updateCartItems(response.body.cart);
            this.updateCartQuantity(response.body.cart.products.length);
          }
        })
      );
  }

  removeItemToCart(products: any) {
    let url = 'api/v2/oms/cart?action=removeItemsToExistingCart';
    const currentUser = null;
    console.log(this.cookieService.get('_STR_C'))
    //cartPayload['cookie']=this.cookieService.get('_STR_C')
    return this.httpService.put<any>(`${environment.apiServer}`+url,products).pipe(
        tap((response:any) => {
          console.log(response.body.cart);
          if(response.statusCode === 200){
            this.updateCartItems(response.body.cart);
            this.updateCartQuantity(response.body.cart.products.length);
          }
        })
      );
  }

  getCityList() {
    const endPoint = environment.apiServer;
    const newUrl = 'api/citystate' + `?action=map`;
    return this.httpService.get<any>(endPoint + newUrl);
  }

  getShippingMethods(){
    const endPoint = environment.apiServer;
    let newUrl = 'api/admin/settings/shipping-setting' + "?action=getShippingData&pageNumber=1&pageSize=25"
    return this.httpService.get<any>(endPoint + newUrl);
  }

  getDefaultLocation() {
    const endPoint = environment.apiServer;
    const url = 'api/admin/settings/location' +`?action=getDefaultLocation`;
    return this.httpService.get<any>(endPoint + url);
  }

  checkAddress(){
    const endPoint = environment.apiServer;
    let cookie = this.cookieService.get('_STR_C');
    const newUrl = 'api/admin/customers' + `?action=getCostomerDataByCookie&cookie=${cookie}`;
    return this.httpService.get<any>(endPoint + newUrl);
  }

  getStateList() {
    const endPoint = environment.apiServer;
    const newUrl = 'api/citystate' + `?action=states`;
    return this.httpService.get<any>(endPoint + newUrl);
  }

  createAddress(payload){
    const endPoint = environment.apiServer;
    const newUrl = 'api/admin/customers';
    return this.httpService.post<any>(endPoint + newUrl,payload);

  }

  getTaxData(){
    const endPoint = environment.apiServer;
    const newUrl = 'api/admin/settings/tax-setting';
    const url =
    newUrl +
    `?action=getTaxData&pageNumber=${1}&pageSize=${20}`
    return this.httpService.get<any>(endPoint + url);
  }

  getPinCodeData(pincode){
    const newUrl = 'https://api.postalpincode.in/pincode/'+pincode;
    return this.http.get<any>(newUrl);

  }
  addAddress(address){
    const endPoint = environment.apiServer;
    const newUrl = 'api/admin/customers' + '?action=addNewAddress';
    return this.httpService.put<any>(endPoint + newUrl,address);

  }

  orderCheckOut(order){
    const endPoint = environment.apiServer;
    const newUrl = 'api/v2/oms/order';
    return this.httpService.post<any>(endPoint + newUrl,order);

  }

  orderpaymentVerification(order){
    const endPoint = environment.apiServer;
    const newUrl = 'api/v2/oms/payment/verify-payment';
    return this.httpService.post<any>(endPoint + newUrl,order);

  }


}
