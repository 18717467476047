import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";



@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private router: Router) {}

  //Collection Navigations
  goToCollection(collectionName: string) {
    this.router.navigate([`collection/${collectionName}`]);
  }

  goToCollectionPage() {
    this.router.navigate([`collection`]);
  }

  goToHome(){
    this.router.navigate(['']);
  }

  goToCheckOut(){
    this.router.navigate(['checkout']);
  }

  goToProductDetailsPage(productId){
    this.router.navigate([`product/${productId}`])
  }
  
  goToOrderStatusPage(orderId,orderType){
    this.router.navigate([`order-status`],{queryParams:{orderId:orderId,orderType:orderType}})
  }
}
